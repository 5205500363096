import {Component, Inject, ViewChild} from '@angular/core';
import {GalleryComponent, ImageItem} from "ng-gallery";
import {MatButton} from "@angular/material/button";
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from "@angular/material/dialog";
import {Location} from "ng-geo-spatial-api";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-loc-preview-dialog',
  standalone: true,
  imports: [
    GalleryComponent,
    MatButton,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle
  ],
  templateUrl: './loc-preview-dialog.component.html',
  styleUrl: './loc-preview-dialog.component.scss'
})
export class LocPreviewDialogComponent {
  @ViewChild("DIALOG_GALLERY", {static: true}) gallery: GalleryComponent;
  images: ImageItem[] = [];
  name: string;
  description: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public location: Location,
    private dialogRef: MatDialogRef<LocPreviewDialogComponent>
  ) {
    const basePath = environment.API_BASE_PATH + "/api/image/download/"
    this.name = location.name;
    this.description = location.description;
    this.images = location.images.map(i => new ImageItem(
      {
        src: basePath + i.medium.name,
        thumb: basePath + i.small.name,
        alt: i.description
      }
    ));
  }

  onMore() {
    this.gallery.reset()
    this.dialogRef.close(this.location.route)
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
